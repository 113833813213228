
.home_container {
    row-gap: 7.25rem;
}


.home_content {
    grid-template-columns: 116px repeat(2,1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home_social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home_social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home_social-icon:hover {
    color: var(--title-color-dark);
}

.home_title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home_intro {
    font-size: var(--h3-font-size);

    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);

}

.home_text {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}




.home_img {
    background:url(../../assets/img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 10%);
    order: 1;
    justify-self: center;
    width: 250px;
    height: 250px;
    border-radius: 8px;
    

}
.home_scroll {
    margin-left: 9.25rem;
}


.home_scroll-name {
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home_scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
    animation: fade-in-out 1.75s ease infinite;
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
        
    }

    50% {
        opacity: 0 ;
        transform:translateY(.25rem);
    }
}



@media screen and (max-width:992px) {
  .home_content {
    grid-template-columns: 100px repeat(2,1fr);
  }

  .home_intro {
    margin-bottom: var(--mb-1);

  }
  .home_text {
    min-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home_img {
    width: 225px;
    height: 225px;
    box-shadow: inset 0 0 0 8px rgb(255, 255, 255 / 30%);
  }
  .home_scroll {
    margin-left: 8rem;
  }

 
}

@media screen and (max-width:768px) {
   .home_content{
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
   }

   .home_img {
    order: initial;
    justify-self: initial;
   }

   .home_data {
    grid-column: 2/3;
   }

   .home_img {
    box-shadow: inset 0 0 0 6px rgb(255, 255, 255 / 30%);
    width: 200px;
    height: 200px;
   }

   .home_scroll {
    display: none;
   }
}



@media screen and (max-width:375px) {
   .home_img {
    width: 180px;
    height: 180px;
   }

　.home_data {
    grid-column: 1/3;
}
}