.works_container {
    width: 750px;
}


.works_card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
   padding:1.5rem 2rem;
   border-radius: 1.5rem;
   margin-bottom: var(--mb-3);
}

.works_img {
    width: max-content;
    height: 200px;
    margin-bottom: var(--mb-1);
 
    
    
}

.works_name {
    font-size: var(--mormal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}

.works_intro {
    font-size: var(--small-font-size);
}

.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--title-color) !important;
}

@media screen and (max-width:992px) {
   .works_container {
    width: initial;
   }

   .works_card {
    padding: 1.25rem 1.5rem;
   }
    
}