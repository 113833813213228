.about_container {
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    column-gap: 4rem;
   
    
}

.about_img {
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about_intro {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}

@media screen and (max-width:992px) {
 .about_container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
 }
 .about_data {
    text-align: center;
 }

 .about_intro {
    padding: 0 5rem;
    margin-bottom: 2rem;
 }
    
}

@media screen and (max-width:768px) {
  
}

@media screen and (max-width:576px) {
    .about_intro {
        padding: 0;
    }
}

@media screen and (max-width:375px) {
  
    
}