

/* もっとbottonの前 */

.skills_container {
    grid-template-columns: repeat(3,280px);
    column-gap: 3rem;
    justify-content: center;
   
}

.skills_content {
    position: relative;
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;

}

.skills_title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-semi-bold);
    text-align: left;
    margin-bottom: var(--mb-1-5);
}


.skills_box {
   display:flex;
   justify-content:start;
   column-gap: 2.5rem;
   
   
}

.skills_group {
    display: grid;
    grid-template-columns: repeat(2,1fr);
   column-gap: 2rem;
   row-gap: 1rem;
}



.skills .uil-check-circle {
    font-size: 1rem;
    color: var(--title-color);
}

.skills_name {
    font-size: var(--small-font-size);
    font-weight: var(--font-semi-bold);
    line-height: 18px;
}

.skills_level {
    font-size: var(--small-font-size);
}



/* detail bottonは以降 */

.skills_button {
    position: relative;
    margin-top: 2.25rem;
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.skills_button-icon {
    font-size:1rem ;
    transition: .3s;
}

.skills_button::before {
    content: '';
   position: absolute;
   background-color: var(--title-color-dark);
   width:0;
   height: 1px;
   left: 0;
   bottom: 0;
   transition: .3s ease;
}

.skills_button:hover::before {
    width: 100%;
}

.skills_button:hover .skills_button-icon {
    transform: translateX(00.25rem);
}

.skills_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
} 

.skills_modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.skills_modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.skills_modal-title,
.skills_modal-intro {
    text-align: center;
}

.skills_modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.skills_modal-intro {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.active-modal {
    opacity: 1;
    visibility: visible;
}




@media screen and (max-width:992px) {
   .skills_container {
    grid-template-columns: max-content;
    row-gap: 2rem;
   }

}



@media screen and (max-width:576px) {
    .skills_container {
        grid-template-columns: 1fr;
    }

    .skills_modal-content {
        padding: 4.5rem 1.5rem 2.5rem;
    }
   .skills_modal-intro {
    padding: 0;
   }
}

@media screen and (max-width:375px) {
  .skills_box {
    column-gap: 1.25rem;
  }
}
